/*.header-class {*/
/*    transform: rotate(-90deg);*/
/*    transform-origin: center;*/
/*    white-space: nowrap;*/
/*    position: absolute !important;*/
/*    width: 153px !important;*/
/*}*/

/*.monthlyGradeCnt .cell-header {*/
/*    min-width: 60px !important;*/
/*    width: 60px !important;*/
/*}*/

/*.monthlyGradeCnt .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders{*/
/*    overflow: unset !important;*/
/*    border: unset !important;*/
/*}*/
/*.monthlyGradeCnt .MuiDataGrid-main.css-204u17-MuiDataGrid-main {*/
/*    padding: 90px 0;*/
/*}*/

/*.monthlyGradeCnt .css-yrdy0g-MuiDataGrid-columnHeaderRow {*/
/*    margin-bottom: 95px;*/
/*    position: relative;*/
/*}*/

.MuiDataGrid-columnHeaderTitle {
    height: 200px; /* Adjust the height value as per your requirement */
}
.MuiDataGrid-columnHeaderTitleContainer{
    height: 200px; /* Adjust the height value as per your requirement */
}
.MuiDataGrid-columnHeaders{
    height: 200px; /* Adjust the height value as per your requirement */
}
.MuiDataGrid-columnHeader{
    height: 200px; /* Adjust the height value as per your requirement */
}
div.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
    max-height: 200px !important;
}

.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor.header-class {
    height: 200px !important;
    min-width: 85px !important;
    max-width: 85px !important;
}

.cell-header.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
    min-width: 85px !important;
    max-width: 85px !important;
}
