body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'DejaVu Sans', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
    height: 100%
}

@font-face {
    font-weight: normal;
    font-style: normal;
}


.prettyScroll::-webkit-scrollbar {
    width: 5px;
}

.prettyScroll::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
}

.prettyScroll::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 5px;
}

.prettyScroll::-webkit-scrollbar-thumb:hover {
    background: #c4c4c4;
}

/* Header navigation styling */

.HeaderTabNav .MuiAppBar-root{
    width: unset !important;
}

.HeaderTabNav .Styled\(MuiBox\)-root-18{
    height: unset !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorSecondary.MuiIconButton-sizeSmall{
    position: absolute !important;
    top: 10px !important;
    right: 0 !important;
}