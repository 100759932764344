@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'DejaVu Sans', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
    height: 100%
}

@font-face {
    font-weight: normal;
    font-style: normal;
}


.prettyScroll::-webkit-scrollbar {
    width: 5px;
}

.prettyScroll::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
}

.prettyScroll::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 5px;
}

.prettyScroll::-webkit-scrollbar-thumb:hover {
    background: #c4c4c4;
}

/* Header navigation styling */

.HeaderTabNav .MuiAppBar-root{
    width: unset !important;
}

.HeaderTabNav .Styled\(MuiBox\)-root-18{
    height: unset !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorSecondary.MuiIconButton-sizeSmall{
    position: absolute !important;
    top: 10px !important;
    right: 0 !important;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: "Roboto", "Dejavu Sans";
    /* overflow-y: hidden; */
}

.main-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.content-wrapper {
    flex-grow: 1;
    width: calc(100% - 250px);
    background-color: #e3f2fa;
}

.MuiTableHead-root {
    background-color: #e3f2fa;
}

.filter-bar > *,
.filter-bar .MuiFormControl-root,
.filter-bar .MuiButtonBase-root {
    margin-right: 8px;
}


.page-tab:hover {
    background-color: rgba(255, 255, 255, .03);
}

.page-tab .MuiTab-root {
    padding: 6px 6px 6px 20px;
    width: 100% !important;
}

.page-tab .Mui-selected {
    background-color: rgba(255, 255, 255, .08)
}


/* diff css */
.diff-table-style-name {
    font-size: 12px;
    font-family: Consolas, serif;
    margin-top: 2px;
    width: 100%;
    border-collapse: collapse;
}

.diff-table-style-name > tbody > tr {
    border: solid gainsboro 1px;
}

.diff-table-style-name > tbody > tr > td {
    border-left: solid gainsboro 1px;
    padding: 5px;
}

.diff-table-style-name > tbody > tr:first-child {
    border-left: none;
}

.diff-table {
    font-size: 12px;
    width: 100%;
    border-collapse: collapse;
}

.diff-table > tbody > tr {
    border: solid #DDDDDD 1px;
}

.diff-table > tbody > tr > td {
    border-left: solid #DDDDDD 1px;
    padding: 5px;
}

.diff-table > tbody > tr:first-child {
    border-left: none;
}

.added-background {
    background: #8AD38B;
}

.removed-background {
    background: #E79B9B;
}

.edited-background {
    background: #F5DE9C;
}

.column-header-background {
    background: #E3E3E3;
}

.diff-state-header {
    background: #B8D1E3;
}



/* Status colors */
.status-PERFORMED {
    color: rgb(76, 175, 80);
}
.status-EDITED {
    color: rgb(255, 152, 0);
}
.status-REJECTED {
    color: rgb(255, 23, 68);
}
.status-PENDING {
    color: rgb(0, 136, 255);
}

/* Custom Table absence */

.absenceMain .css-gg4vpm{
    justify-content: center;
}

.absenceMain__cnt{
    overflow-x:scroll;
    max-width:1535px;
    margin: 50px auto;
    padding: 0 5px;
}

.absenceTable{
    width: 100%;
    padding: 5px 10px;
    overflow-y: scroll;
    border-collapse: collapse;
}

.absenceTable__date{
    border: 1px solid #000;
    min-width: 30px;
    max-width: 42px;
    height: 78px;
    padding-top: 24px;
    white-space: nowrap;
}

.absenceTable__attend{
    width: 42px;
    height: 25px;
    border: none;
    text-align: center;
}

.absenceTable__date div{
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.absenceTable__nameSurname{
    min-width: 230px;
}
.absenceTable tr{
    border: 1px solid #000;
}
.absenceTable td{
    border: 1px solid #000;
}
.absenceTable th{
    border: 1px solid #000;
}

.behaviourCnt .MuiDataGrid-withBorderColor.MuiDataGrid-columnHeader--filledGroup{
    width: 653.5px !important;
}

.behaviourCnt{
    overflow-x: scroll;
}

/* Header navigation styling */

.HeaderTabNav{
    background: #e3f2fa;
    padding-top: 88px;
}

.HeaderTabNav .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer{
    display: flex !important;
    flex-direction: column !important;
}

.HeaderTabNav .MuiTabs-flexContainer.css-k008qs {
    display: flex !important;
    flex-direction: column !important;
}

.HeaderTabNav .MuiBox-root-19{
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

/*.header-class {*/
/*    transform: rotate(-90deg);*/
/*    transform-origin: center;*/
/*    white-space: nowrap;*/
/*    position: absolute !important;*/
/*    width: 153px !important;*/
/*}*/

/*.monthlyGradeCnt .cell-header {*/
/*    min-width: 60px !important;*/
/*    width: 60px !important;*/
/*}*/

/*.monthlyGradeCnt .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders{*/
/*    overflow: unset !important;*/
/*    border: unset !important;*/
/*}*/
/*.monthlyGradeCnt .MuiDataGrid-main.css-204u17-MuiDataGrid-main {*/
/*    padding: 90px 0;*/
/*}*/

/*.monthlyGradeCnt .css-yrdy0g-MuiDataGrid-columnHeaderRow {*/
/*    margin-bottom: 95px;*/
/*    position: relative;*/
/*}*/

/*.MuiDataGrid-columnHeaderTitle {*/
/*    height: 200px; !* Adjust the height value as per your requirement *!*/
/*}*/
/*.MuiDataGrid-columnHeaderTitleContainer{*/
/*    height: 200px; !* Adjust the height value as per your requirement *!*/
/*}*/
/*.MuiDataGrid-columnHeaders{*/
/*    height: 200px; !* Adjust the height value as per your requirement *!*/
/*}*/
/*.MuiDataGrid-columnHeader{*/
/*    height: 200px; !* Adjust the height value as per your requirement *!*/
/*}*/
/*div.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {*/
/*    max-height: 200px !important;*/
/*}*/

/*.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor.header-class {*/
/*    height: 200px !important;*/
/*    min-width: 85px !important;*/
/*    max-width: 85px !important;*/
/*}*/

/*.cell-header.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {*/
/*    min-width: 85px !important;*/
/*    max-width: 85px !important;*/
/*}*/

/*.header-class {*/
/*    transform: rotate(-90deg);*/
/*    transform-origin: center;*/
/*    white-space: nowrap;*/
/*    position: absolute !important;*/
/*    width: 153px !important;*/
/*}*/

/*.monthlyGradeCnt .cell-header {*/
/*    min-width: 60px !important;*/
/*    width: 60px !important;*/
/*}*/

/*.monthlyGradeCnt .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders{*/
/*    overflow: unset !important;*/
/*    border: unset !important;*/
/*}*/
/*.monthlyGradeCnt .MuiDataGrid-main.css-204u17-MuiDataGrid-main {*/
/*    padding: 90px 0;*/
/*}*/

/*.monthlyGradeCnt .css-yrdy0g-MuiDataGrid-columnHeaderRow {*/
/*    margin-bottom: 95px;*/
/*    position: relative;*/
/*}*/

.MuiDataGrid-columnHeaderTitle {
    height: 200px; /* Adjust the height value as per your requirement */
}
.MuiDataGrid-columnHeaderTitleContainer{
    height: 200px; /* Adjust the height value as per your requirement */
}
.MuiDataGrid-columnHeaders{
    height: 200px; /* Adjust the height value as per your requirement */
}
.MuiDataGrid-columnHeader{
    height: 200px; /* Adjust the height value as per your requirement */
}
div.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
    max-height: 200px !important;
}

.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor.header-class {
    height: 200px !important;
    min-width: 85px !important;
    max-width: 85px !important;
}

.cell-header.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
    min-width: 85px !important;
    max-width: 85px !important;
}

/*.header-class {*/
/*    transform: rotate(-90deg);*/
/*    transform-origin: center;*/
/*    white-space: nowrap;*/
/*    position: absolute !important;*/
/*    width: 153px !important;*/
/*}*/

/*.monthlyGradeCnt .cell-header {*/
/*    min-width: 60px !important;*/
/*    width: 60px !important;*/
/*}*/

/*.monthlyGradeCnt .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders{*/
/*    overflow: unset !important;*/
/*    border: unset !important;*/
/*}*/
/*.monthlyGradeCnt .MuiDataGrid-main.css-204u17-MuiDataGrid-main {*/
/*    padding: 90px 0;*/
/*}*/

/*.monthlyGradeCnt .css-yrdy0g-MuiDataGrid-columnHeaderRow {*/
/*    margin-bottom: 95px;*/
/*    position: relative;*/
/*}*/

.MuiDataGrid-columnHeaderTitle {
    height: 200px; /* Adjust the height value as per your requirement */
}
.MuiDataGrid-columnHeaderTitleContainer{
    height: 200px; /* Adjust the height value as per your requirement */
}
.MuiDataGrid-columnHeaders{
    height: 200px; /* Adjust the height value as per your requirement */
}
.MuiDataGrid-columnHeader{
    height: 200px; /* Adjust the height value as per your requirement */
}
div.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
    max-height: 200px !important;
}

.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor.header-class {
    height: 200px !important;
    min-width: 85px !important;
    max-width: 85px !important;
}

.cell-header.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
    min-width: 85px !important;
    max-width: 85px !important;
}

.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: url(/static/media/mtiebi2.d177b2c5.jpg) no-repeat center center fixed;
    background-size: cover;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
}

.login-container {
    position: relative;
    width: 300px;
    height: 350px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #333333; /* Update label and input text color */
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.3); /* Add box-shadow */
}
.login-container h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.login-container form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login-container form .form-group {
    margin-bottom: 20px;
}

.login-container form label {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
}

.login-container form input[type="text"],
.login-container form input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px ;
    border: none;
    border-radius: 3px;
    background: rgba(231, 229, 229, 0.8);
    color: #0a0808; /* Update text color */
    font-size: 14px;
}

.login-container form input[type="text"]::-webkit-input-placeholder, .login-container form input[type="password"]::-webkit-input-placeholder {
    color: #999999; /* Update placeholder text color */
}

.login-container form input[type="text"]::placeholder,
.login-container form input[type="password"]::placeholder {
    color: #999999; /* Update placeholder text color */
}

.login-container form input[type="text"]:focus,
.login-container form input[type="password"]:focus {
    outline: none;
    background: rgb(222, 222, 222); /* Update background color on focus */
}

.login-container form button[type="submit"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background: #0e7fb7;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.3s;
}

.login-container form button[type="submit"]:hover {
    background: #075675;
}

.login-page form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login-page form .form-group {
    margin-bottom: 20px;
}

/*.login-page form label {*/
/*    font-size: 14px;*/
/*    font-weight: 400;*/
/*}*/

/*.login-page form input[type="text"],*/
/*.login-page form input[type="password"] {*/
/*    width: 100%;*/
/*    padding: 10px;*/
/*    border: none;*/
/*    border-radius: 3px;*/
/*    background: rgba(255, 255, 255, 0.8);*/
/*    color: #333;*/
/*    font-size: 14px;*/
/*}*/

/*.login-page form input[type="text"]::placeholder,*/
/*.login-page form input[type="password"]::placeholder {*/
/*    color: #999;*/
/*}*/

/*.login-page form button[type="submit"] {*/
/*    width: 100%;*/
/*    padding: 10px;*/
/*    border: none;*/
/*    border-radius: 3px;*/
/*    background: #0e7fb7;*/
/*    color: #ffffff;*/
/*    font-size: 16px;*/
/*    font-weight: 500;*/
/*    cursor: pointer;*/
/*    transition: background 0.3s;*/
/*}*/

/*.login-page form button[type="submit"]:hover {*/
/*    background: #075675;*/
/*}*/


/* Giorgi CSS */

.loginCnt{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    padding: 50px;
}

.loginCnt__img img{
    max-width: 250px;
}

.loginCnt__circleWrap{
    max-width: 500px;
    width: 100%;
    margin: auto;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50%;
    top: calc(50px + 9vh);
}

.loginCnt__circle{
    max-width: 500px;
    height: 450px;
    border-radius: 50%;
    position: absolute;
    width: 100%;
}

.loginCnt__circle.img{
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 0;
    background-image: url(/static/media/loginImg.5a8da0ed.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 3;
}

.loginCnt__circle.blue{
    left: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 225px;
    background-color: #01619b;
    z-index: 2;
}

.loginCnt__circle.gray{
    left: 0px;
    background-color: #f0f2f1;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 80px;
    z-index: 1;
}

.loginCnt__circle.darkGray{
    left: 64%;
    background-color: #a2a2a2;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 100px;
    z-index: 1;
}

.loginCnt__circle.whiteOpacity{
    left: 63%;
    background-color: #fdfdfd;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 152px;
    z-index: 4;
    opacity: 0.5;
}

.loginCnt__circle.purple{
    left: 53%;
    top: 25px;
    background-color: #4c75bd;
    z-index: 5;
    opacity: 0.7;
}

.loginCnt__footer{
    color: #1a3658;
    font-size: 28px;
    position: absolute;
    left: 410px;
    width: 305px;
    text-align: center;
    top: 565px;
}

.formCnt{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 210px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.formCnt__title{
    color: #fff;
    font-size: 30px;
    letter-spacing: 2px;
    margin-top: 80px;
    padding: 15px 0;
}

.formCnt__input{
    width: 100%;
    padding: 8px 5px;
    border-radius: 12px;
    border: none;
    margin-bottom: 15px;
}

.formCnt__btnCnt{
    width: 100%;
    display: flex;
    justify-content: center;
}

.formCnt__btnCnt button{
    padding: 10px 30px;
    background: #6597ef;
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}
