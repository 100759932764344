* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: "Roboto", "Dejavu Sans";
    /* overflow-y: hidden; */
}

.main-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.content-wrapper {
    flex-grow: 1;
    width: calc(100% - 250px);
    background-color: #e3f2fa;
}

.MuiTableHead-root {
    background-color: #e3f2fa;
}

.filter-bar > *,
.filter-bar .MuiFormControl-root,
.filter-bar .MuiButtonBase-root {
    margin-right: 8px;
}


.page-tab:hover {
    background-color: rgba(255, 255, 255, .03);
}

.page-tab .MuiTab-root {
    padding: 6px 6px 6px 20px;
    width: 100% !important;
}

.page-tab .Mui-selected {
    background-color: rgba(255, 255, 255, .08)
}


/* diff css */
.diff-table-style-name {
    font-size: 12px;
    font-family: Consolas, serif;
    margin-top: 2px;
    width: 100%;
    border-collapse: collapse;
}

.diff-table-style-name > tbody > tr {
    border: solid gainsboro 1px;
}

.diff-table-style-name > tbody > tr > td {
    border-left: solid gainsboro 1px;
    padding: 5px;
}

.diff-table-style-name > tbody > tr:first-child {
    border-left: none;
}

.diff-table {
    font-size: 12px;
    width: 100%;
    border-collapse: collapse;
}

.diff-table > tbody > tr {
    border: solid #DDDDDD 1px;
}

.diff-table > tbody > tr > td {
    border-left: solid #DDDDDD 1px;
    padding: 5px;
}

.diff-table > tbody > tr:first-child {
    border-left: none;
}

.added-background {
    background: #8AD38B;
}

.removed-background {
    background: #E79B9B;
}

.edited-background {
    background: #F5DE9C;
}

.column-header-background {
    background: #E3E3E3;
}

.diff-state-header {
    background: #B8D1E3;
}



/* Status colors */
.status-PERFORMED {
    color: rgb(76, 175, 80);
}
.status-EDITED {
    color: rgb(255, 152, 0);
}
.status-REJECTED {
    color: rgb(255, 23, 68);
}
.status-PENDING {
    color: rgb(0, 136, 255);
}

/* Custom Table absence */

.absenceMain .css-gg4vpm{
    justify-content: center;
}

.absenceMain__cnt{
    overflow-x:scroll;
    max-width:1535px;
    margin: 50px auto;
    padding: 0 5px;
}

.absenceTable{
    width: 100%;
    padding: 5px 10px;
    overflow-y: scroll;
    border-collapse: collapse;
}

.absenceTable__date{
    border: 1px solid #000;
    min-width: 30px;
    max-width: 42px;
    height: 78px;
    padding-top: 24px;
    white-space: nowrap;
}

.absenceTable__attend{
    width: 42px;
    height: 25px;
    border: none;
    text-align: center;
}

.absenceTable__date div{
    transform: rotate(-90deg);
}

.absenceTable__nameSurname{
    min-width: 230px;
}
.absenceTable tr{
    border: 1px solid #000;
}
.absenceTable td{
    border: 1px solid #000;
}
.absenceTable th{
    border: 1px solid #000;
}

.behaviourCnt .MuiDataGrid-withBorderColor.MuiDataGrid-columnHeader--filledGroup{
    width: 653.5px !important;
}

.behaviourCnt{
    overflow-x: scroll;
}

/* Header navigation styling */

.HeaderTabNav{
    background: #e3f2fa;
    padding-top: 88px;
}

.HeaderTabNav .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer{
    display: flex !important;
    flex-direction: column !important;
}

.HeaderTabNav .MuiTabs-flexContainer.css-k008qs {
    display: flex !important;
    flex-direction: column !important;
}

.HeaderTabNav .MuiBox-root-19{
    height: fit-content !important;
}
